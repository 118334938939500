<template>
  <form
    v-if="!newsletterState.success"
    data-test="newsletter"
    @submit="onSubmit"
  >
    <UiFormField
      id="newsletter-email"
      type="email"
      aria-autocomplete="list"
      autocomplete="email"
      name="email"
      :placeholder="props.content.email"
      :aria-label="props.content.email"
      rules="required|email"
      variant="blank"
      class="rounded-none border-x-0 border-b border-t-0 border-white bg-transparent px-0 pb-3 pt-0 text-lg text-white placeholder:text-white"
      :show-validation-message-outside-input="false"
    />
    <ul
      v-if="Object.keys(errors).length || newsletterState.failure"
      class="mt-6 text-sm"
    >
      <li
        v-for="(error, field) in errors"
        :key="field"
        class="mb-1 rounded-md bg-white px-4 py-2 text-errors"
      >
        {{ error }}
      </li>
      <li
        v-if="newsletterState.failure"
        class="mb-1 rounded-md bg-white px-4 py-2 text-errors"
      >
        {{ newsletterState.failure }}
      </li>
    </ul>
    <div
      class="mt-7 flex flex-col items-start justify-between text-white lg:flex-row lg:items-center"
    >
      <CustomerConsents
        ref="ConsentsComponent"
        area="marketing"
        :is-loading="newsletterState.loading ?? false"
        checkbox-variant="secondary"
        :marketing-email-only="true"
        class="text-white lg:w-10/12 lg:pr-2"
        data-test="newsletter-customer-consents"
      />
      <UiButton
        class="grow lg:w-1/12"
        type="submit"
        variant="transparent"
        :disabled="newsletterState.loading"
      >
        {{ props.content.cta }}
      </UiButton>
    </div>
  </form>
  <div
    v-else
    class="text-white"
  >
    <p class="border-x-0 border-b border-t-0 border-white pb-3 text-lg">
      {{
        typeof newsletterState.success === "string"
          ? newsletterState.success
          : newsletterState.success.title
      }}
    </p>
    <p
      v-if="
        typeof newsletterState.success !== 'string'
          && newsletterState.success.message
      "
      class="mt-3 text-lg"
    >
      {{ newsletterState.success.message }}
    </p>
  </div>
</template>

<script setup lang="ts">
import UiButton from '@ui/components/UiButton/UiButton.vue'
import UiFormField from '@ui/components/UiForm/UiFormField/UiFormField.vue'
import CustomerConsents from '@customer/components/CustomerConsents/CustomerConsents.vue'

const props = withDefaults(defineProps<Newsletter>(), {
  content: () => ({
    consent: '',
    email: '',
    cta: '',
  }),
})

const { useForm } = await import('vee-validate')

interface contentNewsletter {
  consent: string
  email: string
  cta: string
}

interface Newsletter {
  content: contentNewsletter
}

interface NewsletterState {
  loading?: boolean
  success?:
    | null
    | string
    | {
      title: string
      message: string
    }
  failure?: null | string
}

const { t } = useI18n()
const { handleSubmit, errors } = useForm()

const messages = {
  SUBSCRIBED: {
    title: t('cms_thank_you'),
    message: t(
      'cms_check_your_email_and_click_on_the_link_we_sent_you_to_receive_newsletters',
    ),
  },
  NOT_ACTIVE: 'NOT_ACTIVE',
  UNCONFIRMED: 'UNCONFIRMED',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
}

const newsletterState = ref<NewsletterState>({
  loading: false,
  success: null,
  failure: null,
})

function setState(state: NewsletterState) {
  return Object.assign(newsletterState.value, state)
}

const ConsentsComponent = ref<InstanceType<typeof CustomerConsents>>()

const onSubmit = handleSubmit(async (values, { resetForm }) => {
  if (ConsentsComponent.value?.consents.length) {
    const isValid = await ConsentsComponent.value.validate()
    if (!isValid) {
      return
    }
  }

  setState({
    success: null,
    failure: null,
    loading: true,
  })

  const { data } = await useAsyncData('newsletter', () =>
    GqlNewsletter({ email: values.email }))

  if (data.value?.subscribeEmailToNewsletter?.status === 'SUBSCRIBED') {
    await ConsentsComponent.value?.setConsents({ email: values.email })
    setState({
      success: messages[data.value?.subscribeEmailToNewsletter.status],
    })
    resetForm()
  }

  setState({
    loading: false,
  })
})
</script>
